import React, {useEffect, useState} from 'react';

type Props = {
    title?: string;
    name?: string;
    value: number;
    error?: string;
    min?: number;
    max?: number;
    required?: boolean;
    onChange: (data: any) => void;
};

export const NumberInput: React.FC<Props> = (props) => {
    const handleClickMinus = (e: any) => {
        if (props.min !== undefined && props.value <= props.min) {
            props.onChange(props.min);
        } else {
            props.onChange(props.value - 1);
        }
    }
    const handleClickPlus = (e: any) => {
        if (props.max !== undefined && props.value >= props.max) {
            props.onChange(props.max);
        } else {
            props.onChange(props.value + 1);
        }
    }
    const handleChange = (e: any) => {
        if (props.max !== undefined && e.target.value >= props.max) {
            props.onChange(props.max);
        } else if (props.min !== undefined && e.target.value <= props.min) {
            props.onChange(props.min);
        } else {
            props.onChange(parseInt(e.target.value))
        }
    }

    return (
        <div className="number-input">
            <div className="number-input__container">
                <span className="number-input__button number-input__button_minus" onClick={handleClickMinus}>-</span>
                <input type="number" className="number-input__text" {...props} onChange={handleChange}/>
                <span className="number-input__button number-input__button_plus" onClick={handleClickPlus}>+</span>
            </div>
        </div>
    );
};
