import * as React from 'react';
import {useEffect} from 'react';
import {PopupWithForm} from '../PopupWithForm';
import {User} from "../../api";
import {useAuth} from "../../contexts/AuthContext";
import {useFormAndValidation} from "../../hooks/useFormAndValidation";
import {Input} from "../Input";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onUpdateUser: (user: User) => void;
};

export const EditAvatarPopup = ({isOpen, onClose, onUpdateUser}: Props) => {
    const {user} = useAuth();
    const {values, handleChange, errors, isValid, resetForm} = useFormAndValidation();

    function handleSubmit() {
        return onUpdateUser(values);
    }

    useEffect(() => {
        resetForm(user);
    }, [isOpen]);

    return (
        <PopupWithForm
            title='Обновить аватар'
            name='edit_avatar'
            onClose={onClose}
            isOpen={isOpen}
            buttonDisabled={!isValid || (values.avatar === user.avatar)}
            onSubmit={handleSubmit}
        >
            <fieldset className='form__set'>
                <Input
                    title='Ссылка на аватар:'
                    name='avatar'
                    type='url'
                    onChange={handleChange}
                    error={errors.avatar}
                    value={values.avatar}
                />
            </fieldset>
        </PopupWithForm>
    );
};
