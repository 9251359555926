import React from 'react';

type Props = {
    children: any;
    name: string;
    checked: boolean;
    error?: string;
    type?: string;
    minLength?: number;
    maxLength?: number;
    required?: boolean;
    onChange: (arg: any) => void;
    dark?: boolean;
};

export const CheckBox = ({
    children,
    name,
    checked,
    error,
    type,
    minLength,
    maxLength,
    required,
    onChange,
    dark = false
}: Props) => {
    const [focused, setFocused] = React.useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    return (
        <label className='form__field form__field_type_row'>
            <input
                className={`form__checkbox form__input${dark ? ' form__input_dark' : ''} ${error && 'form__input_type_error'}`}
                name={name}
                type={type ?? 'text'}
                minLength={minLength}
                maxLength={maxLength}
                required={required}
                onChange={onChange}
                checked={checked}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <span className='form__checkbox-item'/>
            <span className={`form__text`}>{children}</span>
            <span className={`form__input-error${error && !focused ? ' form__input-error_active' : ''}`}>{error}</span>
        </label>
    );
};
