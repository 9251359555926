/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Token } from '../models/Token';
import type { TokenObtain } from '../models/TokenObtain';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Obtain Token
     * @param requestBody
     * @returns Token OK
     * @throws ApiError
     */
    public static obtainToken(
        requestBody: TokenObtain,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
