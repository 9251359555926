enum Router {
    HOME = '/',
    SCHEDULE = '/matches',
    MATCH = '/matches/:matchId',
    BETS = '/bets',
    PROFILE = '/profile',
    UNSUBSCRIBE = '/unsubscribe',
    LOGIN = '/login',
    REGISTER = '/register',
    RESTORE_PASSWORD_LINK = '/reset-password',
    RESTORE_PASSWORD = '/reset-password/:sessionId',
    NOT_FOUND = '/error/404',
}

export { Router };
