import * as React from 'react';
import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import Header from '../../components/Header';
import {SignForm} from '../../components/SignForm';
import {Input} from '../../components/Input';
import {Link, useNavigate} from 'react-router-dom';
import {Router} from '../../router';
import {InfoTooltip} from '../../components/InfoTooltip';
import {useFormAndValidation} from '../../hooks/useFormAndValidation';
import {UsersService} from "../../api";
import {CheckBox} from "../../components/CheckBox";
import {UserAgreement} from "../../components/UserAgreement";

const Register: FunctionComponent = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [defaultValues, setDefaultValues] = useState({});
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isUserAgreementOpen, setIsUserAgreementOpen] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const {values, handleChange, errors, isValid, resetForm} = useFormAndValidation(defaultValues);

    const initialValues = useMemo(() => {
        return {email: '', username: '', password: ''};
    }, []);

    useEffect(() => {
        resetForm(initialValues);
    }, [resetForm, initialValues]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!values.email || !values.password) {
            return;
        }
        return UsersService.creteUser(values)
            .then((data) => {
                if (data) {
                    setShowSuccessPopup(true);
                } else {
                    throw data;
                }
            })
            .catch((err) => {
                setErrorMessage(err.body.detail ?? err);
                setShowErrorPopup(true);
            });
    };

    const handleConditionAgree = () => {
        setDefaultValues({conditions: true});
        handleClosePopups();
    }

    const handleClosePopups = () => {
        setShowSuccessPopup(false);
        setShowErrorPopup(false);
        setIsUserAgreementOpen(false);
    };

    return (
        <>
            <Header/>
            <SignForm
                name='register'
                title='Регистрация'
                onSubmit={handleSubmit}
                buttonDisabled={!isValid}
                buttonLabel='Зарегистрироваться'
            >
                <fieldset className='form__set'>
                    <Input
                        title='Имя пользователя'
                        name='username'
                        onChange={handleChange}
                        error={errors.username}
                        value={values.username}
                        dark={true}
                        required={true}
                    />
                    <Input
                        title='Email'
                        name='email'
                        type='email'
                        onChange={handleChange}
                        error={errors.email}
                        value={values.email}
                        dark={true}
                        required={true}
                    />
                </fieldset>
                <fieldset className='form__set'>
                    <Input
                        title='Пароль'
                        name='password'
                        type='password'
                        minLength={8}
                        onChange={handleChange}
                        error={errors.password}
                        value={values.password}
                        dark={true}
                        required={true}
                    />
                </fieldset>
                <fieldset className='form__set'>
                    <CheckBox
                        name='personal_data'
                        type='checkbox'
                        onChange={handleChange}
                        error={errors.personal_data}
                        checked={values.personal_data}
                        dark={true}
                        required={true}
                    >Согласен на обработку персональных данных</CheckBox>
                    <CheckBox
                        name='conditions'
                        type='checkbox'
                        onChange={handleChange}
                        error={errors.conditions}
                        checked={values.conditions}
                        dark={true}
                        required={true}
                    >Принимаю <button className='form__text form__inline-button'
                                      type='button'
                                      onClick={() => setIsUserAgreementOpen(true)}
                    >условия</button> использования сервиса
                    </CheckBox>
                </fieldset>
            </SignForm>
            <p className='form__tip'>
                Уже зарегистрированы?{' '}
                <Link className='form__tip' to={Router.LOGIN}>
                    Войти
                </Link>
            </p>
            <InfoTooltip isOpen={showSuccessPopup}
                         onClose={handleClosePopups}
                         message='Вы успешно зарегистрировались!'
                         type='success'
                         footer={
                             <button
                                 className='form__submit' style={{alignSelf: 'center'}}
                                 onClick={() => navigate(Router.LOGIN)}
                             >Войти</button>
                         }
            />
            <InfoTooltip isOpen={showErrorPopup}
                         onClose={handleClosePopups}
                         message={errorMessage}
                         type='error'
            />
            <UserAgreement isOpen={isUserAgreementOpen}
                           onClose={handleClosePopups}
                           onConfirm={handleConditionAgree}
            />
        </>
    );
};

export {Register};
