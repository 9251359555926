/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SeasonSchema } from '../models/SeasonSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataService {

    /**
     * Get Season
     * @returns SeasonSchema OK
     * @throws ApiError
     */
    public static getSeason(): CancelablePromise<SeasonSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data/',
        });
    }

}
