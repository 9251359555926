import React, {useEffect, useMemo} from 'react';
import {AuthService, OpenAPI, TokenObtain, User, UsersService} from "../api";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../hooks/useLocalStorage";


interface AuthContextInterface {
    user: User;
    setUser: (user: User) => void;
    login: (user: TokenObtain) => any;
    logout: (callback?: VoidFunction) => void;
}

export const AuthContext = React.createContext<AuthContextInterface>(null!);

export const AuthProvider = ({children}: { children: React.ReactNode }) => {
    const [user, setUser] = useLocalStorage("user", undefined);
    const navigate = useNavigate();
    OpenAPI.TOKEN = localStorage.getItem('access') as string;

    useEffect(() => {
        if (OpenAPI.TOKEN) {
            UsersService.getMe()
                .then(setUser)
                .catch((err) => {
                    if (err.status === 401) {
                        logout();
                    }
                });
        }
    }, [OpenAPI.TOKEN]);

    const login = async (user: TokenObtain, callback?: VoidFunction) => {
        return AuthService.obtainToken(user)
            .then(({access, user}) => {
                if (access) {
                    localStorage.setItem('access', access);
                }
                setUser(user);
            })
            .catch((data) => {
                throw data;
            });
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('access');
        navigate("/", {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            setUser,
            login,
            logout
        }),
        [user]
    );
    return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>);
};

export const useAuth = () => React.useContext(AuthContext);