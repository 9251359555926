import * as React from 'react';
import {Router} from "../../router";
import {Link} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {useMenuItems} from "../../hooks/useMenuItems";
import {displayUserName} from "../../utils/helpers";
import {SITE_NAME} from "../../config/config";
import {useEffect} from "react";

type Props = {
    fixed?: boolean
};

const Header: React.FC<Props> = ({fixed}) => {

    const {user, logout} = useAuth();
    const items = useMenuItems();

    const [menuIsOpen, setMenuIsOpen] = React.useState(false);

    useEffect(() => {
        function closeByEscape(evt: KeyboardEvent) {
            if (evt.key === 'Escape') {
                setMenuIsOpen(false);
            }
        }

        if (menuIsOpen) {
            document.addEventListener('keydown', closeByEscape);
            return () => {
                document.removeEventListener('keydown', closeByEscape);
            };
        }
    }, [menuIsOpen]);


    const handleOverlay = (e: any) => {
        if (e.target === e.currentTarget) {
            setMenuIsOpen(false);
        }
    };

    function handleToggleMenu() {
        setMenuIsOpen(!menuIsOpen);
    }


    return (
        <header className={`header${fixed ? ' header_fixed' : ''}`}>
            <a className='header__logo' href={Router.HOME}/>

            <div className={`header__menu${menuIsOpen ? ' header__menu_opened' : ''}`} onClick={handleOverlay}>
                {items.filter((item) => !!user ? item.is_auth : !item.is_auth).map((item, index) => {
                    if (item.active) {
                        document.title = `${item.label} | ${SITE_NAME}`;
                    }
                    return (
                        <Link className='header__menu-item' to={item.href!} key={index}>
                            {item.label}
                        </Link>
                    );
                })}
                {
                    user && (
                        <>
                            <Link to={Router.PROFILE} className='header__menu-item'>{displayUserName(user)}</Link>
                            <button className='header__menu-item header__menu-item_signout' onClick={() => logout()}>
                                Выйти
                            </button>
                        </>
                    )
                }
            </div>
            <button className={`header__menu-button${menuIsOpen ? ' header__menu-button_opened' : ''}`}
                    type="button"
                    aria-label="кнопка меню"
                    onClick={handleToggleMenu}
            />
        </header>
    );
};

export default React.memo(Header);