import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { Home } from '../pages/Home';
import { Register } from '../pages/Register';
import { Login } from '../pages/Login';
import { Router } from '../router';
import { AuthProvider } from '../contexts/AuthContext';
import { presetGpnDark, Theme } from "@consta/uikit/Theme";
import { OpenAPI } from "../api";
import { Matches } from "../pages/Matches";
import Bets from "../pages/Bets";
import { REACT_APP_BASE_API_PATH, SITE_NAME } from "../config/config";
import { ProfilePage } from "../pages/ProfilePage";
import { NotFound } from "../pages/NotFound/NotFound";
import { AlertProvider } from "../contexts/AlertContext";
import { Alert } from "./Error";
import { Unsubscribe } from "../pages/Unsubscribe";
import { RestorePassword } from "../pages/RestorePassword";
import { RestorePasswordLink } from "../pages/RestorePasswordLink";
import { Match } from "../pages/Match";

const App = () => {
    OpenAPI.BASE = REACT_APP_BASE_API_PATH ?? '';
    document.title = `${SITE_NAME}`;

    return (
        <Theme preset={presetGpnDark}>
            <AlertProvider>
                <AuthProvider>
                    <Alert />
                    <Routes>
                        <Route path={Router.REGISTER} element={<Register />} />
                        <Route path={Router.LOGIN} element={<Login />} />
                        <Route path={Router.RESTORE_PASSWORD_LINK} element={<RestorePasswordLink />} />
                        <Route path={Router.RESTORE_PASSWORD} element={<RestorePassword />} />
                        <Route
                            path={Router.HOME}
                            element={
                                <ProtectedRoute>
                                    <Home />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={Router.SCHEDULE}
                            element={
                                <ProtectedRoute>
                                    <Matches />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={Router.MATCH}
                            element={
                                <ProtectedRoute>
                                    <Match />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={Router.BETS}
                            element={
                                <ProtectedRoute>
                                    <Bets />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={Router.PROFILE}
                            element={
                                <ProtectedRoute>
                                    <ProfilePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={Router.UNSUBSCRIBE}
                            element={
                                <ProtectedRoute>
                                    <Unsubscribe />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={Router.NOT_FOUND} element={<NotFound />} />
                        <Route path="*" element={<Navigate to={Router.NOT_FOUND} replace />} />
                    </Routes>
                </AuthProvider>
            </AlertProvider>
        </Theme>
    );
};

export default App;
