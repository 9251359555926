import * as React from 'react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Header from '../../components/Header';
import { SignForm } from '../../components/SignForm';
import { Input } from '../../components/Input';
import { useParams } from 'react-router-dom';
import { InfoTooltip } from '../../components/InfoTooltip';
import { useFormAndValidation } from '../../hooks/useFormAndValidation';
import { UsersService } from "../../api";
import { UserAgreement } from "../../components/UserAgreement";

const RestorePasswordLink: FunctionComponent = () => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [defaultValues, setDefaultValues] = useState({});
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isUserAgreementOpen, setIsUserAgreementOpen] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const {values, handleChange, errors, setErrors, isValid, resetForm} = useFormAndValidation(defaultValues);
    const {sessionId} = useParams();
    const initialValues = useMemo(() => {
        return {email: ''};
    }, []);

    useEffect(() => {
        resetForm(initialValues);
    }, [resetForm, initialValues]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!values.email) {
            return;
        }
        return UsersService.sendRestorePasswordEmail(values)
            .then((data) => {
                if (data) {
                    setShowSuccessPopup(true);
                } else {
                    throw data;
                }
            })
            .catch((err) => {
                setErrorMessage(err.body.detail ?? err);
                setShowErrorPopup(true);
            });
    };

    const handleConditionAgree = () => {
        setDefaultValues({conditions: true});
        handleClosePopups();
    }

    const handleClosePopups = () => {
        setShowSuccessPopup(false);
        setShowErrorPopup(false);
        setIsUserAgreementOpen(false);
    };

    return (
        <>
            <Header />
            <SignForm
                name='resetPasswordLink'
                title='Восстановление пароля'
                onSubmit={handleSubmit}
                buttonDisabled={!isValid}
                buttonLabel='Отправить ссылку'
            >
                <fieldset className='form__set'>
                    <Input
                        title='Email'
                        name='email'
                        type='email'
                        onChange={handleChange}
                        error={errors.email}
                        value={values.email}
                        dark={true}
                        required={true}
                    />
                </fieldset>
            </SignForm>
            <InfoTooltip isOpen={showSuccessPopup}
                         onClose={handleClosePopups}
                         message='Ссылка на восстановление пароля отправлена на вашу почту.'
                         type='success'
            />
            <InfoTooltip isOpen={showErrorPopup}
                         onClose={handleClosePopups}
                         message={errorMessage}
                         type='error'
            />
            <UserAgreement isOpen={isUserAgreementOpen}
                           onClose={handleClosePopups}
                           onConfirm={handleConditionAgree}
            />
        </>
    );
};

export { RestorePasswordLink };
