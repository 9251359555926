import React from 'react';
import {Popup} from '../Popup';

type Props = {
    width?: 'default' | 'wide'
    isOpen: boolean;
    onClose: () => void;
    children?: any;
    footer?: any;
    message?: string;
    type?: 'success' | 'error' | undefined;
};

export const InfoTooltip = ({isOpen, onClose, children, footer, message, type, width = 'default'}: Props) => {

    return (
        <Popup isOpen={isOpen} name={width === 'wide' ? 'infotooltip_type_wide' : 'infotooltip'} onClose={onClose}>
            <>
                {type === 'success' && <img style={{alignSelf: 'center'}} src={require('../../images/IconSuccess.png')} alt='success' />}
                {type === 'error' && <img style={{alignSelf: 'center'}} src={require('../../images/IconError.png')} alt='error' />}
                {children}
                {
                    message &&
                    <div className='popup__text popup__text_infotooltip'>{message}</div>
                }
                {footer}
            </>
        </Popup>
    );
};
