import {TournamentRound, User} from "../api";

export const formatDateTime = (dateTime: string) => {
    return new Date(Date.parse(dateTime)).toLocaleString()
}

export const displayMatchStatus = (status: string) => {
    switch (status) {
        case 'NOT_STARTED':
            return 'Не начался';
        case 'LIVE':
            return 'Идёт игра';
        case 'CLOSED':
            return 'Закончен';
        default:
            return status
    }
}


export const displayRoundName = (round: TournamentRound) => {

}

export const displayUserName = (user: User) => {
    let name = '';
    if (user) {
        name = user.username;
        if (name) {
            return name;
        }
        name = user.email;
    }
    return name;
}