import React from "react";
import styles from './Alert.module.css';
import { useAlert } from "../../contexts/AlertContext";
import { Informer } from "@consta/uikit/Informer";
import { Layout } from "@consta/uikit/Layout";
import { Button } from "@consta/uikit/Button";
import { IconClose } from "@consta/uikit/IconClose";

const Alert: React.FC = () => {
    const {alerts, removeTip} = useAlert();
    if (alerts.length) {
        return (
            <div className={styles.Container}>{
                alerts.map((item, key) => {
                    return (
                        <Informer key={key} view="filled" label={<Layout className={styles.Alert__Container}>
                            {item.message}
                            <Button
                                onlyIcon
                                iconLeft={IconClose}
                                view='clear'
                                onClick={() => removeTip(key)}
                                size='s'
                            />
                        </Layout>} status={item.type === 'error' ?
                            'alert' : item.type === 'info' ? 'success' : 'system'}>
                            <button className={`${styles.Button} ${item.type === 'error' ?
                                styles.Button_Error : item.type === 'info' ? styles.Button_Info : ''}`}
                                    type='button' onClick={() => removeTip(key)}></button>
                        </Informer>
                    );
                })
            }</div>
        );
    }
    return <></>;
};

export { Alert };