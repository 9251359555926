import * as React from 'react';
import {Link} from "react-router-dom";
import {UserAgreement} from "../UserAgreement";
import {useState} from "react";

type Props = {};
export const Footer: React.FC<Props> = () => {

    const [isUserAgreementOpen, setIsUserAgreementOpen] = useState(false);

    const handleClosePopups = () => {
        setIsUserAgreementOpen(false);
    };

    return (
        <footer className='footer'>
            <p className='footer__copyright'>&copy;&nbsp;2022&ndash;2024</p>
            <Link to='#'
                  onClick={() => setIsUserAgreementOpen(true)}
                  className='footer__copyright'
            >Пользовательское соглашение</Link>

            <UserAgreement isOpen={isUserAgreementOpen}
                           onClose={handleClosePopups}
            />
        </footer>
    );
};
