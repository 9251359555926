import * as React from 'react';
import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import Header from '../../components/Header';
import {SignForm} from '../../components/SignForm';
import {Input} from '../../components/Input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {Router} from '../../router';
import {InfoTooltip} from '../../components/InfoTooltip';
import {useAuth} from '../../contexts/AuthContext';
import {useFormAndValidation} from '../../hooks/useFormAndValidation';

const Login: FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // @ts-ignore
    const from = location.state?.from?.pathname || Router.HOME;
    const {user, login} = useAuth();
    if (user) {
        navigate(from, {replace: true});
    }

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const {values, handleChange, errors, isValid, resetForm} = useFormAndValidation();

    const initialValues = useMemo(() => {
        return {email: '', password: ''};
    }, []);

    useEffect(() => {
        resetForm(initialValues);
    }, [resetForm, initialValues]);


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!values.email || !values.password) {
            return;
        }

        return login(values)
            .catch((err: any) => {
                setErrorMessage(err.body.detail);
                setShowErrorPopup(true);
            });
    };

    const handleCloseError = () => {
        setShowErrorPopup(false);
    };

    return (
        <>
            <Header />
            <SignForm name='login' title='Вход' onSubmit={handleSubmit} buttonDisabled={!isValid}>
                <fieldset className='form__set'>
                    <Input
                        title='Email'
                        name='email'
                        type='email'
                        onChange={handleChange}
                        error={errors.email}
                        value={values.email}
                        required={true}
                        dark={true}
                    />
                    <Input
                        title='Пароль'
                        name='password'
                        type='password'
                        minLength={8}
                        onChange={handleChange}
                        error={errors.password}
                        value={values.password}
                        required={true}
                        dark={true}
                    />
                </fieldset>
            </SignForm>
            <p className='form__tip'>
                Забыли пароль?{' '}
                <Link className='form__tip' to={Router.RESTORE_PASSWORD_LINK}>
                    Восстановить
                </Link>
            </p>
            <InfoTooltip isOpen={showErrorPopup} onClose={handleCloseError} message={errorMessage}>
                <img src={require('../../images/IconError.png')} alt='error' />
            </InfoTooltip>
        </>
    );
};

export { Login };
