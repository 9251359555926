import React, {FunctionComponent, useEffect, useState} from 'react';
import {Table, TableColumn} from "@consta/uikit/Table";
import {displayMatchStatus, formatDateTime} from "../../utils/helpers";
import {Button} from "@consta/uikit/Button";
import {Router} from "../../router";
import {useNavigate} from "react-router-dom";
import {useBreakpoints} from "@consta/uikit/useBreakpoints";
import {User} from "@consta/uikit/User";
import {BetsService, DataService, MatchesService, MatchWithBet, SeasonSchema, UserScore} from "../../api";

type Props = {};

export const Main: FunctionComponent<Props> = () => {
    const navigate = useNavigate();
    const breakpoints = useBreakpoints();
    const [data, setData] = useState<SeasonSchema>({'name': ''});
    const [nextMatch, setNextMatch] = useState<MatchWithBet>();
    const [userScores, setUserScores] = useState<UserScore[]>([]);

    const columns: TableColumn<any>[] = [
        {
            title: 'id',
            accessor: 'id',
            renderCell: ({pos}) => pos,
            hidden: true,
        },
        {
            title: 'Имя',
            accessor: 'user',
            renderCell: ({user}) => (
                <User size={breakpoints['2xs'] ? 'm' : breakpoints['5xs'] ? 's' : undefined}
                      name={user.username}
                      info={user.about}
                      avatarUrl={user.avatar}
                />
            ),
            width: breakpoints['l'] || breakpoints['2xs'] || breakpoints['3xs'] ? undefined : breakpoints['4xs'] ? 140 : breakpoints['5xs'] ? 120 : undefined
        },
        {
            title: 'Место',
            accessor: 'position',
            align: 'right',
            renderCell: (row) => row.score !== null ? row.position : undefined,
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 70 : breakpoints['5xs'] ? 50 : undefined
        },
        {
            title: 'Очков',
            accessor: 'score',
            align: 'right',
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 70 : breakpoints['5xs'] ? 50 : undefined
        },
        {
            title: !breakpoints['3xs'] && breakpoints['4xs'] || !breakpoints['3xs'] && !breakpoints['4xs'] && breakpoints['5xs'] ? 'Точн' : 'Точность',
            accessor: 'accuracy',
            align: 'right',
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 70 : breakpoints['5xs'] ? 50 : undefined
        },
    ];

    useEffect(() => {
        BetsService.listTop10()
            .then((data: any) => setUserScores([...data]));
    }, []);

    useEffect(() => {
        MatchesService.getMatchNext()
            .then((data: any) => setNextMatch(data));
    }, []);

    useEffect(() => {
        DataService.getSeason()
            .then((data: any) => setData(data));
    }, []);


    return (
        <main className='content'>
            <section className='hero'>
                <div className='hero__container'>
                    <h1 className="text-white">{data.name}</h1>
                    {
                        nextMatch &&
                        <>
                            <h2 className='hero__text'>Ближайшая
                                игра: {nextMatch.round_name} Группа {nextMatch.round?.group}</h2>
                            <h2 className='hero__text'>
                                <a
                                        className='link'
                                        href={Router.MATCH.replace(':matchId', nextMatch.id.toString())}
                                >{formatDateTime(nextMatch.scheduled_at)}{nextMatch.status === 'LIVE' ? `, ${displayMatchStatus(nextMatch.status)}` : ''}
                                </a>
                            </h2>
                            <div className='lastgame'>
                                <div className='lastgame__container'>
                                    <div className='lastgame__team lastgame__team_home'>
                                        <div className='flag'>
                                            <img className='flag__image' src={nextMatch.home?.team.logo}
                                                 alt={nextMatch.home?.team.name}/>
                                        </div>
                                        <h3>{nextMatch.home?.team.name}</h3>
                                    </div>
                                        {
                                            nextMatch.status === 'LIVE' &&
                                            <div className='match__bet__container match__bet__container_nextmatch'>
                                                <div className='match__bet__score'>
                                                    <span>{nextMatch.home?.score}</span>
                                                    <span>-</span>
                                                    <span>{nextMatch.away?.score}</span>
                                                </div>
                                            </div>
                                        }
                                    <div className='lastgame__team lastgame__team_away'>
                                        <div>
                                            <div className='flag'>
                                                <img className='flag__image' src={nextMatch.away?.team.logo}
                                                     alt={nextMatch.away?.team.name}/>
                                            </div>
                                            <h3>{nextMatch.away?.team.name}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <Button label='Перейти к прогнозам'
                            style={{marginTop: 50, backgroundColor: '#8a1538'}}
                            onClick={() => navigate(Router.SCHEDULE)}
                    />
                </div>
            </section>
            {
                userScores.length  >  0  &&
                <section className='rates'>
                    <Table columns={columns} rows={userScores}
                           size={!breakpoints['3xs'] && breakpoints['4xs'] ? 's' : 'm'} />
                </section>
            }
        </main>
    );
};
