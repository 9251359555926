/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Match } from '../models/Match';
import type { MatchWithBet } from '../models/MatchWithBet';
import type { MatchWithBets } from '../models/MatchWithBets';
import type { UserWithMatchesWithBet } from '../models/UserWithMatchesWithBet';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MatchesService {

    /**
     * Get Match
     * @param matchId
     * @returns MatchWithBets OK
     * @throws ApiError
     */
    public static getMatch(
        matchId: number,
    ): CancelablePromise<MatchWithBets> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/matches/{match_id}',
            path: {
                'match_id': matchId,
            },
        });
    }

    /**
     * Get Match Last
     * @returns Match OK
     * @throws ApiError
     */
    public static getMatchLast(): CancelablePromise<Match> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/matches/last/',
        });
    }

    /**
     * Get Match Next
     * @returns MatchWithBet OK
     * @throws ApiError
     */
    public static getMatchNext(): CancelablePromise<MatchWithBet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/matches/next/',
        });
    }

    /**
     * List Matches
     * @returns Match OK
     * @throws ApiError
     */
    public static listMatches(): CancelablePromise<Array<Match>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/matches',
        });
    }

    /**
     * List Matches With Bet
     * @returns MatchWithBet OK
     * @throws ApiError
     */
    public static listMatchesWithBet(): CancelablePromise<Array<MatchWithBet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/matches/bets/',
        });
    }

    /**
     * List Matches With Users Bet
     * @returns UserWithMatchesWithBet OK
     * @throws ApiError
     */
    public static listMatchesWithUsersBet(): CancelablePromise<Array<UserWithMatchesWithBet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/matches/all-users/',
        });
    }

}
