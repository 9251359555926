import {Responses404} from "@consta/uikit/Responses404";
import {Button} from "@consta/uikit/Button";
import {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import styles from './NotFound.module.css';
import Header from "../../components/Header";
import {Footer} from "../../components/Footer";


export const NotFound: FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header/>
            <Responses404
                className={styles.Error}
                title="Такой страницы не существует"
                description=" "
                actions={
                    <Button view="ghost" label="Вернуться назад" onClick={() => navigate(-1)}/>
                }
            />
            <Footer/>
        </>
    );
}