import * as React from 'react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Header from '../../components/Header';
import { SignForm } from '../../components/SignForm';
import { Input } from '../../components/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoTooltip } from '../../components/InfoTooltip';
import { useFormAndValidation } from '../../hooks/useFormAndValidation';
import { UsersService } from "../../api";
import { UserAgreement } from "../../components/UserAgreement";
import { Router } from "../../router";

const RestorePassword: FunctionComponent = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [defaultValues, setDefaultValues] = useState({});
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [isUserAgreementOpen, setIsUserAgreementOpen] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const {values, handleChange, errors, setErrors, isValid, resetForm} = useFormAndValidation(defaultValues);
    const {sessionId} = useParams();
    const initialValues = useMemo(() => {
        return {password: '', password_repeat: ''};
    }, []);

    useEffect(() => {
        UsersService.checkRestorePasswordLink(sessionId!)
            .catch((err) => {
                navigate(Router.NOT_FOUND);
            });
    }, []);

    useEffect(() => {
        resetForm(initialValues);
    }, [resetForm, initialValues]);

    useEffect(() => {
        if (values.password && values.password_repeat && values.password !== values.password_repeat) {
            setErrors({...errors, 'password': 'Пароли не совпадают'});
        } else {
            setErrors({});
        }
    }, [values]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!values.password || !values.password_repeat || values.password !== values.password_repeat) {
            return;
        }
        return UsersService.restorePassword(sessionId!, values)
            .then((data) => {
                if (data) {
                    setShowSuccessPopup(true);
                } else {
                    throw data;
                }
            })
            .catch((err) => {
                setErrorMessage(err.body.detail ?? err);
                setShowErrorPopup(true);
            });
    };

    const handleConditionAgree = () => {
        setDefaultValues({conditions: true});
        handleClosePopups();
    }

    const handleClosePopups = () => {
        setShowSuccessPopup(false);
        setShowErrorPopup(false);
        setIsUserAgreementOpen(false);
    };

    return (
        <>
            <Header />
            <SignForm
                name='register'
                title='Восстановление пароля'
                onSubmit={handleSubmit}
                buttonDisabled={!isValid || values.password !== values.password_repeat}
                buttonLabel='Сохранить'
            >
                <fieldset className='form__set'>
                    <Input
                        title='Пароль'
                        name='password'
                        type='password'
                        minLength={8}
                        onChange={handleChange}
                        error={errors.password}
                        value={values.password}
                        dark={true}
                        required={true}
                    />
                    <Input
                        title='Повторите пароль'
                        name='password_repeat'
                        type='password'
                        minLength={8}
                        onChange={handleChange}
                        error={errors.password_repeat}
                        value={values.password_repeat}
                        dark={true}
                        required={true}
                    />
                </fieldset>
            </SignForm>
            <InfoTooltip isOpen={showSuccessPopup}
                         onClose={handleClosePopups}
                         message='Пароль сохранен!'
                         type='success'
                         footer={
                             <button
                                 className='form__submit' style={{alignSelf: 'center'}}
                                 onClick={() => navigate(Router.LOGIN)}
                             >Войти</button>
                         }
            />
            <InfoTooltip isOpen={showErrorPopup}
                         onClose={handleClosePopups}
                         message={errorMessage}
                         type='error'
            />
            <UserAgreement isOpen={isUserAgreementOpen}
                           onClose={handleClosePopups}
                           onConfirm={handleConditionAgree}
            />
        </>
    );
};

export { RestorePassword };
