import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';
import {Router} from "../../router";

const ProtectedRoute = ({children}: { children: JSX.Element }) => {
    const {user} = useAuth();
    const location = useLocation();

    if (!user) {
        return <Navigate to={Router.LOGIN} state={{from: location}} replace/>;
    }

    return children;
};


export {ProtectedRoute};
