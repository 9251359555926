import * as React from 'react';
import {PopupWithForm} from '../PopupWithForm';

type Props = {
    title?: string;
    children: any;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const ConfirmPopup = ({title, children, isOpen, onClose, onConfirm}: Props) => {
    const handleSubmit = () => {
        return onConfirm();
    };

    return (
        <PopupWithForm
            title={title ?? 'Вы уверены?'}
            name='remove_place'
            onClose={onClose}
            buttonLabel='Да'
            isOpen={isOpen}
            onSubmit={handleSubmit}
            buttonDisabled={false}
        >
            {children}
        </PopupWithForm>
    );
};
