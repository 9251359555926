import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Footer } from '../../components/Footer';
import { displayMatchStatus, formatDateTime } from "../../utils/helpers";
import { Bet, BetIn, BetInline, BetsService, MatchesService, MatchWithBets, UserScore } from "../../api";
import { SkeletonBrick } from "@consta/uikit/Skeleton";
import { Button } from "@consta/uikit/Button";
import { IconClose } from "@consta/uikit/IconClose";
import { IconCheck } from "@consta/uikit/IconCheck";
import { NumberInput } from "../../components/NumberInput";
import { IconShuffle } from "@consta/uikit/IconShuffle";
import { IconAdd } from "@consta/uikit/IconAdd";
import { IconFunnel } from "@consta/uikit/IconFunnel";
import { useAlert } from "../../contexts/AlertContext";
import { useParams } from "react-router-dom";
import { Table, TableColumn } from "@consta/uikit/Table";
import { useBreakpoints } from "@consta/uikit/useBreakpoints";
import { User } from "@consta/uikit/User";

function Match() {
    const [match, setMatch] = useState<MatchWithBets | undefined>(undefined);
    const [editingBet, setEditingBet] = useState<BetIn>();
    const [betLoading, setBetLoading] = useState<boolean>(false);
    const [place, setPlace] = useState<UserScore>();
    const breakpoints = useBreakpoints();
    const [filterFinished, setFilterFinished] = useState(true);
    const [isRandomConfirmOpen, setIsRandomConfirmOpen] = useState(false);
    const [infoTooltipOpen, setInfoToolTipOpen] = useState(false);
    const {infoTip, errorTip} = useAlert();
    const {matchId} = useParams();
    const closeAllPopups = useCallback(() => {
        setIsRandomConfirmOpen(false);
        setInfoToolTipOpen(false);
    }, []);
    useEffect(() => {
        MatchesService.getMatch(parseInt(matchId!))
            .then((data: MatchWithBets) => setMatch({...data}));
    }, []);

    useEffect(() => {
        BetsService.getMe()
            .then((data: any) => setPlace(data));
    }, []);

    const handleSubmitBet = (bet?: BetInline) => {
        setBetLoading(true);
        if (editingBet) {
            let response;
            if (bet) {
                response = BetsService.updateBet(bet.id!, editingBet);
            } else {
                response = BetsService.createBet(editingBet);
            }
            response
                .then((data: Bet) => {
                    const newBet = {
                        id: data.id!,
                        home: data.home,
                        away: data.away,
                        overtime_home: data.overtime_home,
                        overtime_away: data.overtime_away,
                        penalty_home: data.penalty_home,
                        penalty_away: data.penalty_away,
                        score: undefined
                    };
                    // @ts-ignore
                    setMatch({...match, bet: newBet})
                })
                .then(() => infoTip('Ставка сделана!'))
                .then(() => setEditingBet(undefined))
                .catch((err) => {
                    errorTip(err.body?.detail ?? err.toString());
                    return Promise.reject(err);
                })
                .finally(() => setBetLoading(false));
        }
    };
    const columns: TableColumn<any>[] = [
        {
            title: 'id',
            accessor: 'id',
            renderCell: ({pos}) => pos,
            hidden: true,
        },
        {
            title: 'Имя',
            accessor: 'owner',
            renderCell: ({owner}) => (
                <User size={breakpoints['2xs'] ? 'm' : breakpoints['5xs'] ? 's' : undefined}
                      name={owner.username}
                      info={owner.about}
                      avatarUrl={owner.avatar}
                />
            ),
            width: breakpoints['l'] || breakpoints['2xs'] || breakpoints['3xs'] ? undefined : breakpoints['4xs'] ? 120 : breakpoints['5xs'] ? 60 : undefined
        },
        {
            title: !breakpoints['3xs'] && breakpoints['4xs'] || !breakpoints['3xs'] && !breakpoints['4xs'] && breakpoints['5xs'] ? 'Осн' : 'Осн время',
            accessor: 'main_time',
            align: 'right',
            renderCell: ({home, away}) => `${home}:${away}`,
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 60 : breakpoints['5xs'] ? 50 : undefined
        },
        {
            title: !breakpoints['3xs'] && breakpoints['4xs'] || !breakpoints['3xs'] && !breakpoints['4xs'] && breakpoints['5xs'] ? 'Доп' : 'Доп время',
            accessor: 'overtime',
            align: 'right',
            renderCell: ({overtime_home, overtime_away}) => overtime_home  && overtime_away ? `${overtime_home}:${overtime_away}`  : '-',
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 60 : breakpoints['5xs'] ? 50 : undefined
        },
        {
            title: !breakpoints['3xs'] && breakpoints['4xs'] || !breakpoints['3xs'] && !breakpoints['4xs'] && breakpoints['5xs'] ? 'Пен' : 'Пенальти',
            accessor: 'penalty',
            align: 'right',
            renderCell: ({penalty_home, penalty_away}) => penalty_home && penalty_away ? `${penalty_home}:${penalty_away}` : '-',
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 60 : breakpoints['5xs'] ? 50 : undefined
        },
        {
            title: !breakpoints['3xs'] && breakpoints['4xs'] || !breakpoints['3xs'] && !breakpoints['4xs'] && breakpoints['5xs'] ? 'Очк' : 'Очков',
            accessor: 'score',
            align: 'right',
            width: breakpoints['l'] ? undefined : breakpoints['2xs'] || breakpoints['3xs'] ? 120 : breakpoints['4xs'] ? 60 : breakpoints['5xs'] ? 50 : undefined
        },
    ];


    return (
        <>
            <Header />
            {
                !match
                    ? <div className='matches'>
                        <SkeletonBrick height={200} />
                        <SkeletonBrick height={200} />
                        <SkeletonBrick height={200} />
                    </div>
                    : <div className='matches'>
                        <div className='place'>
                            {
                                place &&
                                <>
                                    <h2>{`Место в рейтинге:  ${place.position}`}</h2>
                                    <h2>{`Всего баллов: ${place.score}`}</h2>
                                </>
                            }
                        </div>
                        {
                            match &&
                            <div key={match.id} className='match'>
                                <h2 className='match__header'>{match.round_name}</h2>
                                <div className='match__schedule'>
                                    <a className='link'
                                       href={match.link!}
                                    >
                                        {formatDateTime(match.scheduled_at)}, <span>{displayMatchStatus(match.status)}</span>
                                    </a>
                                </div>
                                <div className='match__team match__team_home'>
                                    <div className='flag flag_small'>
                                        <img className='flag__image' src={match.home?.team.logo}
                                             alt={match.home?.team.name} />
                                    </div>
                                    <h3 className='match__team__header'>{match.home?.team.name}</h3>
                                </div>
                                <div className='match__team match__team_away'>
                                    <div className='flag flag_small'>
                                        <img className='flag__image' src={match.away?.team.logo}
                                             alt={match.away?.team.name} />
                                    </div>
                                    <h3 className='match__team__header'>{match.away?.team.name}</h3>
                                </div>
                                {
                                    match.status !== 'NOT_STARTED' &&
                                    (
                                        match.home?.overtime_score !== null && match.away?.overtime_score  !== null ?
                                            <div className='match__overtime_score'>
                                                <div className='match__score'>
                                                    <span>{match.home?.overtime_score}</span>
                                                    <span>-</span>
                                                    <span>{match.away?.overtime_score}</span>
                                                </div>
                                                {
                                                    match.home?.penalty_score !== null && match.away?.penalty_score !== null ?
                                                    <span className='match__penalty_score'>Счет в серии пенальти: {match.home?.penalty_score} - {match.away?.penalty_score}</span>
                                                    :
                                                    <span className='match__penalty_score'>Счет в дополнительное время</span>
                                                }
                                            </div>
                                            :
                                            <div className='match__score'>
                                                <span>{match.home?.score}</span>
                                                <span>-</span>
                                                <span>{match.away?.score}</span>
                                            </div>
                                    )
                                }
                                <div className='match__bet'>
                                    {
                                        editingBet && editingBet.match_id === match.id &&
                                        <>
                                            <div className='match__bet__fieldset'>
                                                <h4 className='match__bet__label'>Основной счёт:</h4>
                                                <div className='match__bet__fields'>
                                                    <NumberInput min={0}
                                                                 max={20}
                                                                 value={editingBet.home ?? 0}
                                                                 onChange={(value) => {
                                                                     setEditingBet({
                                                                         ...editingBet,
                                                                         home: value,
                                                                         overtime_home: match.round?.type === 'cup' && value === editingBet.away ? editingBet?.overtime_home ?? value : undefined,
                                                                         overtime_away: match.round?.type === 'cup' && value === editingBet.away ? editingBet?.overtime_away ?? value : undefined,
                                                                         penalty_home: match.round?.type === 'cup' && value === editingBet.away ? editingBet?.penalty_home ?? 0 : undefined,
                                                                         penalty_away: match.round?.type === 'cup' && value === editingBet.away ? editingBet?.penalty_away ?? 0 : undefined
                                                                     })
                                                                 }} />
                                                    <NumberInput min={0}
                                                                 max={20}
                                                                 value={editingBet.away ?? 0}
                                                                 onChange={(value) => setEditingBet({
                                                                     ...editingBet,
                                                                     away: value,
                                                                     overtime_home: match.round?.type === 'cup' && editingBet.home === value ? editingBet?.overtime_home ?? value : undefined,
                                                                     overtime_away: match.round?.type === 'cup' && editingBet.home === value ? editingBet?.overtime_away ?? value : undefined,
                                                                     penalty_home: match.round?.type === 'cup' && editingBet.home === value ? editingBet?.penalty_home ?? 0 : undefined,
                                                                     penalty_away: match.round?.type === 'cup' && editingBet.home === value ? editingBet?.penalty_away ?? 0 : undefined
                                                                 })} />
                                                </div>
                                            </div>
                                            {
                                                match.round?.type === 'cup' &&
                                                editingBet.home === editingBet.away &&
                                                <>
                                                    <div className='match__bet__fieldset'>
                                                        <h4 className='match__bet__label'>Дополнительное время:</h4>
                                                        <div className='match__bet__fields'>
                                                            <NumberInput min={0}
                                                                         max={20}
                                                                         value={editingBet.overtime_home ?? 0}
                                                                         onChange={(value) => {
                                                                             setEditingBet({
                                                                                 ...editingBet,
                                                                                 overtime_home: value,
                                                                                 overtime_away: editingBet?.overtime_away ?? 0,
                                                                                 penalty_home: match.round?.type === 'cup' && value === editingBet?.overtime_away ? editingBet?.penalty_home ?? 0 : undefined,
                                                                                 penalty_away: match.round?.type === 'cup' && value === editingBet?.overtime_away ? editingBet?.penalty_away ?? 0 : undefined
                                                                             })
                                                                         }} />
                                                            <NumberInput min={0}
                                                                         max={20}
                                                                         value={editingBet.overtime_away ?? 0}
                                                                         onChange={(value) => setEditingBet({
                                                                             ...editingBet,
                                                                             overtime_away: value,
                                                                             overtime_home: editingBet?.overtime_home ?? 0,
                                                                             penalty_home: match.round?.type === 'cup' && value === editingBet?.overtime_home ? editingBet?.penalty_home ?? 0 : undefined,
                                                                             penalty_away: match.round?.type === 'cup' && value === editingBet?.overtime_home ? editingBet?.penalty_away ?? 0 : undefined
                                                                         })} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                match.round?.type === 'cup' &&
                                                editingBet.home === editingBet.away &&
                                                editingBet.overtime_home === editingBet.overtime_away &&
                                                <>
                                                    <div className='match__bet__fieldset'>
                                                        <h4 className='match__bet__label'>Серия пенальти:</h4>
                                                        <div className='match__bet__fields'>
                                                            <NumberInput min={0}
                                                                         max={20}
                                                                         value={editingBet.penalty_home ?? 0}
                                                                         onChange={(value) => {
                                                                             setEditingBet({
                                                                                 ...editingBet,
                                                                                 penalty_home: value,
                                                                                 penalty_away: editingBet?.penalty_away ?? 0
                                                                             })
                                                                         }} />
                                                            <NumberInput min={0}
                                                                         max={20}
                                                                         value={editingBet.penalty_away ?? 0}
                                                                         onChange={(value) => setEditingBet({
                                                                             ...editingBet,
                                                                             penalty_away: value,
                                                                             penalty_home: editingBet?.penalty_home ?? 0
                                                                         })} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className='match__bet__buttons'>
                                                <Button className='match__bet__buttons_button'
                                                        onlyIcon={true}
                                                        iconLeft={IconClose}
                                                        view='ghost'
                                                        width='full'
                                                        aria-label='закрыть'
                                                        label='закрыть'
                                                        onClick={() => setEditingBet(undefined)}
                                                />
                                                <Button className='match__bet__buttons_button'
                                                        onlyIcon={true}
                                                        iconLeft={IconShuffle}
                                                        view='ghost'
                                                        width='full'
                                                        aria-label='случайный счёт'
                                                        label='случайный счёт'
                                                        onClick={() => {
                                                            setEditingBet({
                                                                match_id: match.id!,
                                                                home: Math.floor(Math.random() * 5),
                                                                away: Math.floor(Math.random() * 5)
                                                            })
                                                        }}
                                                />
                                                <Button className='match__bet__buttons_button'
                                                        onlyIcon={true}
                                                        iconLeft={IconCheck}
                                                        view='ghost'
                                                        width='full'
                                                        aria-label='сохранить'
                                                        label='сохранить'
                                                        loading={betLoading}
                                                        onClick={() => handleSubmitBet(match.bet)}
                                                />
                                            </div>
                                        </>
                                    }
                                    {
                                        !(editingBet && editingBet.match_id === match.id) &&
                                        match.bet &&
                                        <>
                                            <h4 className='match__bet__label'>Основной счёт:</h4>
                                            <div className='match__bet__container'>
                                                <div className='match__bet__score'>
                                                    <span>{match.bet.home}</span>
                                                    <span>-</span>
                                                    <span>{match.bet.away}</span>
                                                </div>
                                                {
                                                    match.status === 'NOT_STARTED'
                                                    && new Date(match.scheduled_at).getTime() > new Date().getTime() &&
                                                    <button
                                                        className='profile__edit-button match__bet__buttons_button_edit'
                                                        type='button'
                                                        aria-label='редактировать счет'
                                                        onClick={() => {
                                                            setEditingBet({
                                                                match_id: match.id!,
                                                                home: match.bet ? match.bet.home : 0,
                                                                away: match.bet ? match.bet.away : 0,
                                                                overtime_home: match.round?.type === 'cup' ? match.bet?.overtime_home ?? 0 : undefined,
                                                                overtime_away: match.round?.type === 'cup' ? match.bet?.overtime_away ?? 0 : undefined,
                                                                penalty_home: match.round?.type === 'cup' ? match.bet?.penalty_home ?? 0 : undefined,
                                                                penalty_away: match.round?.type === 'cup' ? match.bet?.penalty_away ?? 0 : undefined,
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                            {
                                                match.bet.overtime_home !== null && match.bet.overtime_away !== null &&
                                                <>
                                                    <h4 className='match__bet__label'>Доп. время:</h4>
                                                    <div className='match__bet__container'>
                                                        <div className='match__bet__score'>
                                                            <span>{match.bet.overtime_home}</span>
                                                            <span>-</span>
                                                            <span>{match.bet.overtime_away}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                match.bet.penalty_home !== null && match.bet.penalty_away !== null &&
                                                <>
                                                    <h4 className='match__bet__label'>Серия пенальти:</h4>
                                                    <div className='match__bet__container'>
                                                        <div className='match__bet__score'>
                                                            <span>{match.bet.penalty_home}</span>
                                                            <span>-</span>
                                                            <span>{match.bet.penalty_away}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                match.status === 'CLOSED' && match.bet.score! >= 0 &&
                                                <>
                                                    <h4 className='match__bet__label'>Итого: {match.bet.score} балла(-ов).</h4>
                                                </>
                                            }
                                        </>
                                    }
                                    {
                                        !(editingBet && editingBet.match_id === match.id) &&
                                        !match.bet &&
                                        match.status === 'NOT_STARTED' &&
                                        new Date(match.scheduled_at).getTime() > new Date().getTime() &&
                                        <button className='match__add-button'
                                                type='button'
                                                onClick={() => {
                                                    setEditingBet({
                                                        match_id: match.id!,
                                                        home: match.bet ? match.bet.home : 0,
                                                        away: match.bet ? match.bet.away : 0
                                                    })
                                                }}
                                        ><IconAdd size='s' />Добавить прогноз</button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
            }
            {
                match?.bets &&
                <section className='rates'>
                    <Table columns={columns} rows={match.bets}
                           size={!breakpoints['3xs'] && breakpoints['4xs'] ? 's' : 'm'} />
                </section>
            }
            <Footer />
        </>
    );
}

export { Match };
