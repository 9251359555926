/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeUserPassword } from '../models/ChangeUserPassword';
import type { RestoreUserPassword } from '../models/RestoreUserPassword';
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Get Me
     * @returns User OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/me/',
        });
    }

    /**
     * Crete User
     * @param requestBody
     * @returns User Created
     * @throws ApiError
     */
    public static creteUser(
        requestBody: UserCreate,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update User
     * @param requestBody
     * @returns User OK
     * @throws ApiError
     */
    public static updateUser(
        requestBody: UserUpdate,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Unsubscribe
     * @returns User OK
     * @throws ApiError
     */
    public static unsubscribe(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/unsubscribe/',
        });
    }

    /**
     * Send Restore Password Email
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static sendRestorePasswordEmail(
        requestBody: RestoreUserPassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/restore-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check Restore Password Link
     * @param sessionId
     * @returns any OK
     * @throws ApiError
     */
    public static checkRestorePasswordLink(
        sessionId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/restore-password/{session_id}',
            path: {
                'session_id': sessionId,
            },
        });
    }

    /**
     * Restore Password
     * @param sessionId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static restorePassword(
        sessionId: string,
        requestBody: ChangeUserPassword,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/restore-password/{session_id}',
            path: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
