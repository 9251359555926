import * as React from 'react';
import {FunctionComponent, useCallback, useState} from 'react';
import Header from '../../components/Header';
import {Footer} from "../../components/Footer";
import {Profile} from "../../components/Profile";
import {EditAvatarPopup} from "../../components/EditAvatarPopup";
import {EditProfilePopup} from "../../components/EditProfilePopup";
import {User, UsersService, UserUpdate} from "../../api";
import {useAuth} from "../../contexts/AuthContext";

const ProfilePage: FunctionComponent = () => {
    const {setUser} = useAuth();
    const [isEditAvatarPopupOpen, setIsEditAvatarPopupOpen] = useState(false);
    const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false);

    const handleEditAvatarClick = () => {
        setIsEditAvatarPopupOpen(true);
    };

    const handleEditProfileClick = () => {
        setIsEditProfilePopupOpen(true);
    };

    const closeAllPopups = useCallback(() => {
        setIsEditAvatarPopupOpen(false);
        setIsEditProfilePopupOpen(false);
    }, []);

    const handleUpdateUser = (user: User) => {
        const userUpdate: UserUpdate = {
            username: user.username,
            about: user.about,
            avatar: user.avatar,
            subscribed: user.subscribed,
        }
        return UsersService.updateUser(userUpdate)
            .then(
                (user) => setUser(user),
                (err) => {
                    console.log(err);
                }
            );
    };
    return (
        <>
            <Header/>
            <Profile onEditProfile={handleEditProfileClick} onEditAvatar={handleEditAvatarClick}/>
            <Footer/>
            <EditAvatarPopup
                isOpen={isEditAvatarPopupOpen}
                onClose={closeAllPopups}
                onUpdateUser={handleUpdateUser}
            />
            <EditProfilePopup
                isOpen={isEditProfilePopupOpen}
                onClose={closeAllPopups}
                onUpdateUser={handleUpdateUser}
            />
        </>
    );
};

export {ProfilePage};
