/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Bet } from '../models/Bet';
import type { BetIn } from '../models/BetIn';
import type { UserScore } from '../models/UserScore';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BetsService {

    /**
     * Create Bet
     * @param requestBody
     * @returns Bet OK
     * @throws ApiError
     */
    public static createBet(
        requestBody: BetIn,
    ): CancelablePromise<Bet> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * List Bets
     * @returns Bet OK
     * @throws ApiError
     */
    public static listBets(): CancelablePromise<Array<Bet>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bets',
        });
    }

    /**
     * Create Random Bets
     * @returns Bet OK
     * @throws ApiError
     */
    public static createRandomBets(): CancelablePromise<Array<Bet>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bets/random/',
        });
    }

    /**
     * Get Bet
     * @param betId
     * @returns Bet OK
     * @throws ApiError
     */
    public static getBet(
        betId: string,
    ): CancelablePromise<Bet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bets/{bet_id}',
            path: {
                'bet_id': betId,
            },
        });
    }

    /**
     * Update Bet
     * @param betId
     * @param requestBody
     * @returns Bet OK
     * @throws ApiError
     */
    public static updateBet(
        betId: string,
        requestBody: BetIn,
    ): CancelablePromise<Bet> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/bets/{bet_id}',
            path: {
                'bet_id': betId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete Bet
     * @param betId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteBet(
        betId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/bets/{bet_id}',
            path: {
                'bet_id': betId,
            },
        });
    }

    /**
     * List Top10
     * @returns UserScore OK
     * @throws ApiError
     */
    public static listTop10(): CancelablePromise<Array<UserScore>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bets/top10/',
        });
    }

    /**
     * Get Me
     * @returns UserScore OK
     * @throws ApiError
     */
    public static getMe(): CancelablePromise<UserScore> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bets/me/',
        });
    }

}
