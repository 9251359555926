import * as React from "react";
import { InfoTooltip } from "../InfoTooltip";

type UserAgreementProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm?: () => void;
}

export const UserAgreement: React.FC<UserAgreementProps> = ({
                                                                isOpen,
                                                                onClose,
                                                                onConfirm
                                                            }) => {
    return (
        <InfoTooltip isOpen={isOpen} onClose={onClose} width='wide'>
            <h1 className='form__header'>Пользовательское соглашение</h1>
            <p className='text'>Добрый день, уважаемые друзья и коллеги!</p>
            <p className='text'>Впереди Чемпионат Европы 2024, к сожалению, без участия команды из России, который
                пройдет с 15 июня 2024 по 17 июля 2024.</p>
            <p className='text'>Предлагаю испытать свою удачу, проверить свое шестое чувство, аналитические
                прогностические способности!</p>
            <p className='text'><strong>Условия регистрации на сайте:</strong></p>
            <ul className='text'>
                <li>вводим почту (любою, но только ту на которую сможете зайти) придумываем пароль (но его не забываем)</li>
                <li>обязательно придумываем ник нейм (по имени не регистрируемся)</li>
            </ul>

            <p className='text'><strong>Условия соревнования:</strong></p>
            <div className='text'>
                <ol className='text'>
                    <li>Участвовать только по «позывному».</li>
                    <li>Заблаговременно необходимо заполнить таблицу, ответ можно менять до начала матча.</li>
                    <li>В таблице заполняем точный счет каждого матча.</li>
                    <li>Плей-офф (расписание) будут заполняться по результатам группового тура.</li>
                    <li>Плей-офф при заполненной ничье можно указать счет пенальти.</li>
                </ol>
                <p className='text'><strong>Баллы:</strong></p>
                <ul className='text'>
                    <li>За верно спрогнозированный исход матча (команда победитель) – 1 балл</li>
                    <li>За верно спрогнозированный результат матча (точный счёт) – 3 балла</li>
                </ul>

                <p className='text'><strong>Плей-офф (при ничье):</strong></p>
                <ul className='text'>
                    <li>За верно спрогнозированный исход матча в дополнительное время, либо по итогам серии пенальти (команда победитель) – 1 балл</li>
                    <li>За верно спрогнозированный результат матча в дополнительное время, либо по итогам пенальти (точный счёт) – 3 балла</li>
                </ul>
                <p className='text'>Таким образом, можно получить максимум 9 баллов за верно спрогнозированный результат
                    матча с разыгранным пенальти.</p>

                <p className='text'><strong>Победители:</strong></p>
                <ul className='text'>
                    <li>1-е место получает 50% от всего банка</li>
                    <li>2-е место получает 30% от всего банка</li>
                    <li>3-е место получает 20% от всего банка</li>
                </ul>
                {
                    onConfirm &&
                    <button name='conditions' className='form__submit' onClick={() => onConfirm()}
                    >Согласен
                    </button>
                }
            </div>
        </InfoTooltip>
    );
}