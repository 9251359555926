import React from 'react';
import Header from '../../components/Header';
import {Footer} from "../../components/Footer";
import {Main} from "../../components/Main";

function Home() {

    return (
        <>
            <Header fixed={true}/>
            <Main/>
            <Footer/>
        </>
    );
}

export {Home};
