import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Table, TableColumn } from "@consta/uikit/Table";
import { MatchesService, MatchWithBet } from "../../api";
import { displayMatchStatus, formatDateTime } from "../../utils/helpers";
import { Layout } from "@consta/uikit/Layout";
import { Text } from "@consta/uikit/Text";
import { Grid } from "@consta/uikit/Grid";
import { useBreakpoints } from "@consta/uikit/useBreakpoints";


const Bets: React.FC = () => {
    const {desktop} = useBreakpoints({desktop: 768});
    const [matches, setMatches] = useState<MatchWithBet[]>([]);

    useEffect(() => {
        MatchesService.listMatchesWithBet()
            .then((data: MatchWithBet[]) => setMatches([...data]));
    }, []);


    const columns: TableColumn<any>[] = [
        {
            title: '№',
            accessor: 'id',
            hidden: true
        },
        {
            title: 'Матч',
            accessor: 'name',
            align: 'center',
            width: desktop ? undefined : 250,
        },
        {
            title: 'Раунд',
            accessor: 'round_name',
            width: 140,
        },
        {
            title: 'Статус',
            accessor: 'status',
            width: 140,
            align: 'center',
            renderCell: ({status}) => displayMatchStatus(status),
            sortable: true
        },
        {
            title: 'Время',
            accessor: 'scheduled_at',
            width: 200,
            align: 'center',
            renderCell: ({scheduled_at}) => formatDateTime(scheduled_at),
            sortable: true
        },
        {
            title: 'Счет',
            accessor: 'score',
            renderCell: ({status, home, away}) => (
                <Layout>
                    <Text size='xl'>{(status === 'CLOSED' && home?.score) ?? '--'}</Text>
                    <Text size='xl'>&nbsp;-&nbsp;</Text>
                    <Text size='xl'>{(status === 'CLOSED' && away?.score) ?? '--'}</Text>
                </Layout>
            ),
            width: 100,
            align: 'center'
        },
        {
            title: 'Моя ставка',
            accessor: 'bet',
            width: 290,
            renderCell: ({bet}) => {
                return (
                    <div style={{width: '100%', height: '100%'}}>
                        <Layout style={{columnGap: 30, alignItems: 'center'}}>
                            <Layout>
                                <Text size='xl'>{(bet && bet.home) ?? '--'}</Text>
                                <Text size='xl'>&nbsp;-&nbsp;</Text>
                                <Text size='xl'>{(bet && bet.away) ?? '--'}</Text>
                            </Layout>
                            {
                                bet && bet.overtime_home !== null && bet.overtime_away !== null &&
                                <Layout>
                                    <Text size='xl'>{bet.overtime_home}</Text>
                                    <Text size='xl'>&nbsp;-&nbsp;</Text>
                                    <Text size='xl'>{bet.overtime_away}</Text>
                                </Layout>
                            }
                            {
                                bet && bet.penalty_home !== null && bet.penalty_away !== null &&
                                <Layout>
                                    <Text size='xl'>{bet.penalty_home}</Text>
                                    <Text size='xl'>&nbsp;-&nbsp;</Text>
                                    <Text size='xl'>{bet.penalty_away}</Text>
                                </Layout>
                            }
                        </Layout>
                    </div>
                );
            }
        },
        {
            title: 'Баллов',
            accessor: 'bet_score',
            renderCell: ({bet}) => (
                <Text size='xl'>{bet?.score ?? '-'}</Text>
            ),
            width: 100,
            align: 'center'
        },
    ];

    return (
        <>
            <Header />
            <Grid className='bets'>
                <Table columns={columns}
                       rows={matches}
                       borderBetweenColumns={true}
                       verticalAlign='center'
                       size='m'
                       zebraStriped='odd'
                />
            </Grid>
            <Footer />
        </>
    );
};

export default React.memo(Bets);
