import {useNavigate, useLocation} from 'react-router-dom';
import {Router} from '../router';
import React from "react";

enum Menu {
    HOME = 'Главная',
    SCHEDULE = 'Мои прогнозы',
    BETS = 'Таблица прогнозов',
    LOGIN = 'Вход',
    REGISTER = 'Регистрация',
}

type Item = {
    label?: string;
    href?: string;
    target?: string;
    active?: boolean;
    onClick?: React.EventHandler<React.MouseEvent>;
    children?: never;
    is_auth?: boolean;
};

type Items = Array<Item & Required<{href: string}>>;

const items: Items = [
    {
        label: Menu.HOME,
        href: Router.HOME,
        is_auth: true
    },
    {
        label: Menu.SCHEDULE,
        href: Router.SCHEDULE,
        is_auth: true
    },
    {
        label: Menu.BETS,
        href: Router.BETS,
        is_auth: true
    },
    {
        label: Menu.LOGIN,
        href: Router.LOGIN,
        is_auth: false
    },
    {
        label: Menu.REGISTER,
        href: Router.REGISTER,
        is_auth: false
    },
];

const useMenuItems = (): Item[] => {
    const navigate = useNavigate()
    let {pathname} = useLocation();

    return items.map((item) => {
        const {href} = item;

        if (pathname === Router.HOME) {
            pathname = Router.HOME;
        }

        return {
            ...item,
            active: pathname.includes(href),
            onClick: (event) => {
                event.preventDefault();
                navigate(href);
            }
        }
    });
};

export {useMenuItems};