import {useCallback, useEffect, useState} from 'react';

export const useFormAndValidation = (defaultValues?: any) => {
    const [values, setValues] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setValues({...values, ...defaultValues});
    }, [defaultValues]);

    const handleChange = (e: any) => {
        let {name, value} = e.target;
        if (e.target.type === 'checkbox') {
            value = e.target.checked;
        }
        setValues({...values, [name]: value});
        setErrors({...errors, [name]: e.target.validationMessage});
        setIsValid(e.target.closest('form').checkValidity());
    };

    const resetForm = useCallback(
        (newValues = {}, newErrors = {}, newIsValid = false) => {
            setValues(newValues);
            setErrors(newErrors);
            setIsValid(newIsValid);
        },
        [setValues, setErrors, setIsValid]
    );

    console.log(values, errors, isValid);
    return {values, handleChange, errors, setErrors, isValid, resetForm, setValues, setIsValid};
};
