import * as React from 'react';
import { FunctionComponent, useCallback, useState } from 'react';
import Header from '../../components/Header';
import { Footer } from "../../components/Footer";
import { User, UsersService, UserUpdate } from "../../api";
import { useAuth } from "../../contexts/AuthContext";
import { ConfirmPopup } from "../../components/ConfirmPopup";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../contexts/AlertContext";

const Unsubscribe: FunctionComponent = () => {
    const {setUser} = useAuth();
    const {infoTip} = useAlert();
    const navigate = useNavigate();

    const closeAllPopups = useCallback(() => {
        navigate('/');
    }, []);

    const handleUpdateUser = () => {
        return UsersService.unsubscribe()
            .then(
                (user) => setUser(user),
                (err) => {
                    console.log(err);
            }).then(() => {
                infoTip('Вы успешно отписались от email-рассылки.')
            });
    };
    return (
        <>
            <Header/>
            <ConfirmPopup
                title="Отписаться от email-уведомлений?"
                isOpen={true}
                onClose={closeAllPopups}
                onConfirm={handleUpdateUser}
            >
                <p>Включить уведомления можно в любой момент в профиле.</p>
            </ConfirmPopup>
        </>
    );
};

export {Unsubscribe};
